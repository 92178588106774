import React from "react"
import { graphql } from "gatsby"
import Seo from '../components/seo'
import Layout from '../containers/layout/layout'
import Banner from '../containers/company/banner'
import About from '../containers/company/about'
import Team from '../containers/company/team'
import JoinOurTeam from '../containers/company/join-our-team'
import Testimonial from '../containers/company/testimonial'

const Company = ({ data }) => {
    const url = data.site.siteMetadata.siteUrl;
    const description = `Découcrez l'histoire de Jardipiscines et faites connaissances avec notre équipe d'expert en création de piscine et d'aménagement paysager.`;

    return(
        <Layout url={url}>
            <Seo title="Notre histoire" description={description}/>
            <Banner/>
            <About/>
            <Team/>
            <JoinOurTeam/>
            <Testimonial/>
        </Layout>
    )
}
export default Company;

export const query = graphql`
query PageHistoireQuery {
  site {
    siteMetadata {
      siteUrl
    }
  }
}
`
