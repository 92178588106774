import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
// import Image from '../../../components/image'
import {
    BlogDetailsWrap,
    BlogDetailsHeader,
} from './blog-template.stc'
import Heading from '../../../components/shared/banner-heading'

const Banner = ({...restProps}) => {
    const {titleStyle} = restProps;
    const bannerQueryData = useStaticQuery(graphql `
        query CompanyBannerQuery {
            pagedataJson(id: {eq: "company_page_data"}) {
                banner_img {
                    childImageSharp {
                        fluid(maxWidth: 1920, maxHeight: 750, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }      
    `);
    const imageData = bannerQueryData.pagedataJson.banner_img.childImageSharp.fluid
    return (
        <div className="banner-area">
            <BlogDetailsWrap>
                <BlogDetailsHeader fluid={imageData}>
                    <div className="row align-items-center" style={{textShadow: "black 0.1em 0.1em 0.2em"}}>
                        <div className="col-2 offset-1">
                            <div className="rn-blog-details-meta-inner">
                                <Heading {...titleStyle}>Histoire</Heading>
                            </div>
                        </div>
                    </div>
                </BlogDetailsHeader>
            </BlogDetailsWrap>
            {/* <Image fluid={imageData} alt="Company Banner"/> */}
        </div>
    )
}

Banner.defaultProps = {
    titleStyle: {
        as: 'h1',
        color: '#fff',
        responsive: {
            small:{
                mb: '22px'
            }
        }
    }
}

export default Banner
 